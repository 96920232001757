import React from "react"
import { Link } from "gatsby"
export default function Footer() {
  return (
    <footer className="py-16 text-center text-xs">
      Copyright 2022 Katharina Kohler | <a href="https://www.instagram.com/katandcoke"> Instagram </a> | 
      <a href="https://de.linkedin.com/in/katharina-kohler-143300117"> LinkedIn </a> | 
      <Link to="/imprint"> Imprint </Link>
    </footer>
  )
}
