/* This example requires Tailwind CSS v2.0+ */
import React from "react"
import { Disclosure } from "@headlessui/react"
import { XIcon, PlusIcon } from "@heroicons/react/outline"
import Navlink from "./Navlink"
import { Link } from "gatsby"

const links = {
  "/about": "about me",
  "/psychology_architecture": "psychology + architecture",
  "/analog": "analog",
  "/digital": "digital",
}

export default function Navbar({ setMobileMenueOpened }) {
  return (
    // <div className="sticky top-0 z-20 bg-hellgrau drop-shadow-sm">
    <Disclosure as="nav" className="container mx-auto pb-4">
      {({ open }) => (
        <>
          {setMobileMenueOpened(open)}
          <div className="pt-8 pl-4 md:pl-0">
            <div className="flex items-center justify-between h-16 relative">
              <div className="flex-shrink-0">
                <Link
                  to="/"
                  className="text-3xl text-slate-900 font-Briar tracking-wide select-none uppercase"
                >
                  Katharina
                  <br />
                  Kohler
                </Link>
              </div>
              <div className="hidden md:block md:ml-6">
                <div className="flex space-x-4">
                  {Object.entries(links).map(([url, text]) => (
                    <Navlink
                      className="text-center hover:text-flieder hover:underline underline-offset-8 decoration-2 uppercase"
                      activeClassName="font-bold underline underline-offset-8 decoration-2"
                      to={url}
                    >
                      {text}
                    </Navlink>
                  ))}
                </div>
              </div>
              <div className="flex md:hidden items-center absolute z-30">
                {/* Mobile menu button */}
                <Disclosure.Button
                  className={
                    "inline-flex items-center justify-center focus:outline-none fixed bottom-40 -right-4 rotate-90 "
                  }
                >
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-5 w-5" aria-hidden="true" />
                  ) : (
                    <PlusIcon className="block h-5 w-5" aria-hidden="true" />
                  )}
                  <span className={"ml-1 text-md uppercase font-bold "}>
                    Menu
                  </span>
                </Disclosure.Button>
              </div>
            </div>
          </div>
          <Disclosure.Panel className="md:hidden">
            <div className="fixed w-full h-full top-0 left-0 z-20 bg-flieder bg-opacity-95">
              <Disclosure.Button className="absolute inset-0 w-full"></Disclosure.Button>
              <div className="absolute inset-x-0 bottom-10 px-10">
                <span className="text-2xl font-bold">MENU</span>
                {Object.entries(links).map(([url, text]) => (
                  <Navlink
                    to={url}
                    className="block py-2 text-left text-base font-medium hover:underline underline-offset-4 decoration-4 uppercase"
                    activeClassName="underline underline-offset-4 decoration-4"
                  >
                    {text}
                  </Navlink>
                ))}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
    // </div>
  )
}
