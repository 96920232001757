import React, { useState } from "react"
import { Helmet } from "react-helmet"
import Footer from "./footer"
import Navbar from "./Navbar"

const getOverflowClass = isMenuOpen => {
  return isMenuOpen ? "overflow-hidden" : ""
}

export default function Layout({ children, title, description }) {
  const [mobileMenuOpened, setMobileMenueOpened] = useState(false)

  return (
    <div className="bg-gray-50 min-h-screen text-slate-900 font-Lato">
      <div className="bg-gray-50 sticky top-0 z-20">
      <Navbar setMobileMenueOpened={setMobileMenueOpened} />
      </div>
      <div className="container mx-auto px-10">
        <Helmet >
          <meta charSet="utf-8" />
          <html lang="en" amp />
          <title>{title}</title>
          <meta name="description" content={description} />
          <body className={getOverflowClass(mobileMenuOpened)} />
        </Helmet>

        <div className="content mt-8">{children}</div>
        <Footer />
      </div>
    </div>
  )
}
