import React from "react"
import { Link } from "gatsby"
export default function Navlink({ children, to, className, activeClassName }) {
  return (
    <Link
      //className="p-2 text-center hover:text-fuchsia-400 hover:underline underline-offset-8 decoration-2"
      //activeClassName="font-bold underline underline-offset-8 decoration-2"
      to={to}
      className={className}
      activeClassName={activeClassName}
    >
      {children}
    </Link>
  )
}
